import styled, { css } from 'styled-components';

import { COLOR_GRAY_00 } from 'styles/variables/colors';

export const Wrapper = styled.main`
  background: ${COLOR_GRAY_00};
  padding: 0;

  ${(props) =>
    props.hasPadding &&
    css`
      padding: 30px 0;
    `}
`;
