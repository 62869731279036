import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';

import { Wrapper } from './styles';

const Props = {
  children: PropTypes.node.isRequired,
  hasPadding: PropTypes.bool.isRequired,
};

export default function PageMain({ children, hasPadding }) {
  return (
    <Wrapper hasPadding={hasPadding}>
      {hasPadding ? <Container>{children}</Container> : children}
    </Wrapper>
  );
}

PageMain.propTypes = Props;
